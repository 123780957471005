<template>
  <div class="container-fluid mobile-datails">
    <section name="ProductTabs" id="tabs" class="container px15 pt20 pb35 cl-black details hidden-xs">
      <div class="tabs">
        <ul class="tablist">
          <li
            v-for="tab in tabs"
            :key="tab.id"
            @mouseover="updateSelected(tab.id)"
            class="animated-dot-bottom"
            :class="{ 'current': selectedTab == tab.id }"
          >
            <p class="weight-900 fs16 m0 secondary-font">
              {{ $t(tab.title) }}
            </p>
          </li>
        </ul>
        <div v-if="selectedTab == 1" class="tab1 modal-tabContent mt20">
          <div class="h4 cl-black">
            <div class="row m0">
              <div class="col-xs-8 tab__container bg-cl-white h5 py40 px30">
                <template v-if="prod.description">
                  <div itemprop="description" v-html="prod.description" />
                </template>
                <template v-else>
                  <p>{{ $t('Product description not found') }}</p>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div v-if="selectedTab == 2" class="tab1 modal-tabContent mt20">
          <div class="h4 cl-black">
            <div class="row m0">
              <div class="col-xs-8 tab__container bg-cl-white h5 py40 px30">
                <p v-if="prod.name" class="description-text">
                  <b class="fs16">{{ $t('Title') }}:</b> {{ prod.name }}
                </p>
                <p v-if="prod.author" class="description-text">
                  <b class="fs16">{{ $t('Author') }}:</b> {{ prod.author }}
                </p>
                <p v-if="prod.publisher" class="description-text">
                  <b class="fs16">{{ $t('Publisher') }}:</b> {{ getLabelFor('publisher') }}
                </p>
                <p v-if="prod.sku" class="description-text">
                  <b class="fs16">{{ $t('Article number') }}:</b> {{ prod.sku }}
                </p>
                <p v-if="prod.ean" class="description-text">
                  <b class="fs16">{{ $t('EAN') }}:</b> {{ prod.ean }}
                </p>
                <p v-if="prod.isbn" class="description-text">
                  <b class="fs16">{{ $t('ISBN') }}:</b> {{ prod.isbn }}
                </p>
                <p v-if="prod.book_premiere && 1 != 1" class="description-text">
                  <b class="fs16">{{ $t('Data wydania') }}:</b> {{ premiereDate }}
                </p>
                <p v-if="prod.book_number_pages" class="description-text">
                  <b class="fs16">{{ $t('Ilość stron') }}:</b> {{ prod.book_number_pages }}
                </p>
                <p v-if="prod.publication_form" class="description-text">
                  <b class="fs16">{{ $t('publication_form_filter') }}:</b> {{ getLabelFor('publication_form') }}
                </p>
                <p v-if="prod.publishing_series" class="description-text">
                  <b class="fs16">{{ $t('publishing_series_filter') }}:</b>  {{ getLabelFor('publishing_series') }}
                </p>
                <p v-if="prod.pattern_type" class="description-text">
                  <b class="fs16">{{ $t('pattern_type_filter') }}:</b>  {{ getLabelFor('pattern_type') }}
                </p>
                <p v-if="prod.brand && getLabelFor('brand')" class="description-text">
                  <b class="fs16">{{ $t('Producer') }}:</b> {{ getLabelFor('brand') }}
                </p>
                <p v-if="prod.sex && getLabelFor('gender')" class="description-text">
                  <b class="fs16">{{ $t('Gender') }}:</b> {{ getLabelFor('gender') }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div v-if="selectedTab == 3" class="tab1 modal-tabContent mt20">
          <div class="h4 cl-black">
            <div class="row m0">
              <div class="col-xs-8 tab__container bg-cl-white h5 py40 px30">
                <div v-show="!showOnlyPrenumerataDelivery" class="row m0">
                  <div class="col-xs-7 pl15">
                    <span class="weight-900 h5">{{ $t('Domestic delivery') }}</span>
                  </div>
                  <div class="col-xs-5 align-center">
                    <span class="weight-900 h5">Płatność</span>
                  </div>
                </div>
                <div v-show="!showOnlyPrenumerataDelivery" class="row m0 middle-xs brdr-bottom-1 brdr-cl-silver dostawa-container" v-for="(shipping, index) in thirdTab" :key="index">
                  <div class="row m0 col-xs-7 pl15 flex between-sm middle-xs">
                    <div class="flex p0" :class="{'col-xs-3': index < 3,'col-xs-2': index === 4 || index === 3}">
                      <img v-lazy="shipping.img" alt="Poczta Polska" height="100%" width="100%">
                    </div>
                    <div class="col-xs-7 p0">
                      <p>{{ shipping.html }}</p>
                    </div>
                  </div>
                  <div class="col-xs-5 pl20">
                    <span v-show="index !== 1" class="weight-900 h5">{{ shipping.prepaid }}</span>
                    <div v-show="index === 1">
                      <span class="weight-900 h5">Paczka o wadze do 350 g - 8,00 zł</span><br>
                      <span class="weight-900 h5">Paczka o wadze 350-500 g - 9,00 zł</span><br>
                      <span class="weight-900 h5">Paczka o wadze 500-1000 g - 11,50 zł</span>
                    </div>
                  </div>
                </div>
                <div v-show="showOnlyPrenumerataDelivery && prod.type_id !== 'single_number'" class="row m0 middle-xs dostawa-container" :class="{'brdr-bottom-1 brdr-cl-silver': !showOnlyPrenumerataDelivery}">
                  <div class="row m0 col-xs-12 pl15 flex middle-xs">
                    <span class="col-xs-3 p0 h5 weight-900">Dostawa prenumerat</span>
                    <span class="p0 h5" :class="showOnlyPrenumerataDelivery ? 'col-xs-9' : 'col-xs-7'">W przypadku zamówienia prenumeraty koszt dostawy wliczony jest w cenę prenumeraty. Prenumerata realizowana jest wyłącznie na terenie Polski. Dostawa realizowana jest przez Pocztę Polską {{ showOnlyPrenumerataDelivery ? '(przesyłka rejestrowana)' : '(przesyłka nierejestrowana)' }}.</span>
                  </div>
                </div>
                <div v-show="showOnlyPrenumerataDelivery && prod.type_id === 'single_number'" class="row m0 middle-xs dostawa-container" :class="{'brdr-bottom-1 brdr-cl-silver': !showOnlyPrenumerataDelivery}">
                  <div class="row m0 col-xs-7 pl15 flex between-sm middle-xs">
                    <div class="flex p0 col-xs-2">
                      <img src="/assets/InPost.svg" alt="Przesyłka kurierska InPost" height="100%" width="100%">
                    </div>
                    <div class="col-xs-7 p0">
                      <p>Przesyłka kurierska InPost</p>
                    </div>
                  </div>
                  <div class="col-xs-5 pl20 weight-900 h5">
                    14,94 zł
                  </div>
                </div>
                <div v-show="!showOnlyPrenumerataDelivery" class="row m0 middle-xs dostawa-container">
                  <div class="row m0 col-xs-12 pl15 flex middle-xs">
                    <span class="col-xs-3 p0 h5 weight-900">Dostawa elektroniczna</span>
                    <span class="p0 h5" :class="showOnlyPrenumerataDelivery ? 'col-xs-9' : 'col-xs-7'">Pliki cyfrowe jak e-wykroje i e-booki dostarczamy <span class="weight-900">za darmo</span> w formie linku do pobrania.</span>
                  </div>
                </div>
                <div v-if="prod.magazine_id !== 155" class="row col-xs-12 p0 h5 mt20 mb10 weight-900">
                  W przypadku zamówienia prenumeraty koszt dostawy wynosi 0 zł. Dostawa realizowana jest przez Pocztę Polską (przesyłka nierejestrowana).
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="selectedTab == 4" class="tabContent tab1 mt20">
          <div class="h4 cl-black">
            <div class="row m0">
              <div class="col-xs-8 tab__container bg-cl-white h5 py40 px30 returns-tab">
                <p class="m0">
                  Chcesz <b>zrezygnować</b> z zakupionego produktu? Nic prostszego. Możesz bezpiecznie zwrócić swój zakup <b>bez podawania przyczyny</b>, w terminie <b>14 dni</b> od odebrania paczki.
                  Odeślij nam produkt, a my <b>zwrócimy Ci środki</b> w ciągu 14 dni. Więcej informacji znajdziesz w zakładce
                  <router-link class="cl-main underline" :to="localizedRoute('/i/zwroty')">
                    <span class="weight-700">Zwroty</span>
                  </router-link>
                </p>
                <p>Jeśli nadajesz paczkę kurierem jako adres mailowy odbiorcy wpisz <b>bok@kultowy.pl</b></p>
                <p v-show="isOmnipack">
                  Ten produkt należy zwrócić do magazyn Omnipack, sprawdź adres w zakładce
                  <router-link class="cl-main underline" :to="localizedRoute('/i/zwroty')">
                    <span class="weight-700">Zwroty</span>
                  </router-link>
                </p>
                <div class="flex middle-xs">
                  <div class="flex flex-col middle-xs">
                    <img class="icons" src="/assets/fonts/return.svg" alt="return icon">
                    <p class="align-center mb0">
                      Przygotuj produkt do zwrotu i zanotuj numer zamówienia
                    </p>
                  </div>
                  <div class="arrow-return right mx20" />
                  <div class="flex flex-col middle-xs">
                    <img class="icons" src="/assets/fonts/local_store.svg" alt="local_store icon">
                    <p class="align-center mb0">
                      Wyślij paczkę do naszego magazynu <router-link class="cl-main underline" :to="localizedRoute('/i/zwroty')">
                      <span class="weight-700">Sprawdź adres</span>
                    </router-link>
                    </p>
                  </div>
                  <div class="arrow-return right mx20" />
                  <div class="flex flex-col middle-xs">
                    <img class="icons" src="/assets/fonts/revenue.svg" alt="revenue icon">
                    <p class="align-center mb0">
                      Zwrot środków wyślemy na rachunek użyty w zamówieniu
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="selectedTab == 5" class="tab3 modal-tabContent">
          <lazy-hydrate when-idle>
            <reviews
              :product-name="getOriginalProduct.name"
              :product-id="getOriginalProduct.id"
              :review-id="'desktop'"
              v-show="isOnline"
            />
          </lazy-hydrate>
        </div>
      </div>
    </section>
    <!-- Mobile version -->
    <section name="ProductAccord" class="bg-cl-main-smoke cl-black visible-xs">
      <ul class="m0 p0">
        <label
          v-for="(tab, index) in tabs"
          :key="tab.id" class="w-100 label-tabs m0 col-xs-12 block  bg-cl-main-smoke secondary-font"
        >
          <!-- Event propagation in reviews bug -->
          <p @click.self="updateSelectedMobile(tab.id)" class="row m0 py15 between-xs w-100">
            <span @click.self="updateSelectedMobile(tab.id)" class="align-left pr15 tab-label" :class="{'active' : selectedTab === tab.id}">{{ $t(tab.title) }}</span>
            <span @click="updateSelectedMobile(tab.id)" class="align-rigth px10"><i :class="selectedTab === tab.id ? 'arrow top' : 'arrow down'" /></span>
          </p>
          <div
            v-if="selectedTab == 1 && index == selectedTab - 1"
            v-html="prod.description ? prod.description : `<p>${$t('Product description not found')}</p>`"
            class="h5 py40 px15 opis-container serif"
            :class="{ 'visible': selectedTab == 1 }"
          />
          <div
            v-if="selectedTab == 2 && index == selectedTab - 1"
            class="expandable-tab opis-container serif"
            :class="{ 'visible': selectedTab == 1 }"
          >
            <div class="row m0">
              <div class="col-xs-12 h5 py30 px15 ">
                <p v-if="prod.name" class="description-text">
                  <b class="fs16">{{ $t('Title') }}:</b> {{ prod.name }}
                </p>
                <p v-if="prod.color && getLabelFor('color')" class="description-text">
                  <b class="fs16">{{ $t('Color') }}:</b> {{ getLabelFor('color') }}
                </p>
                <p v-if="prod.author" class="description-text">
                  <b class="fs16">{{ $t('Author') }}:</b> {{ prod.author }}
                </p>
                <p v-if="prod.publisher" class="description-text">
                  <b class="fs16">{{ $t('Publisher') }}:</b> {{ getLabelFor('publisher') }}
                </p>
                <p v-if="prod.sku" class="description-text">
                  <b class="fs16">{{ $t('Article number') }}:</b> {{ prod.sku }}
                </p>
                <p v-if="prod.ean" class="description-text">
                  <b class="fs16">{{ $t('EAN') }}:</b> {{ prod.ean }}
                </p>
                <p v-if="prod.isbn" class="description-text">
                  <b class="fs16">{{ $t('ISBN') }}:</b> {{ prod.isbn }}
                </p>
                <p v-if="prod.book_premiere && 1 != 1" class="description-text">
                  <b class="fs16">{{ $t('Data wydania') }}:</b> {{ premiereDate }}
                </p>
                <p v-if="prod.book_number_pages" class="description-text">
                  <b class="fs16">{{ $t('Ilość stron') }}:</b> {{ prod.book_number_pages }}
                </p>
                <p v-if="prod.publication_form" class="description-text">
                  <b class="fs16">{{ $t('publication_form_filter') }}:</b> {{ getLabelFor('publication_form') }}
                </p>
                <p v-if="prod.publishing_series" class="description-text">
                  <b class="fs16">{{ $t('publishing_series_filter') }}:</b>  {{ getLabelFor('publishing_series') }}
                </p>
                <p v-if="prod.pattern_type" class="description-text">
                  <b class="fs16">{{ $t('pattern_type_filter') }}:</b>  {{ getLabelFor('pattern_type') }}
                </p>
                <p v-if="prod.brand && getLabelFor('brand')" class="description-text">
                  <b class="fs16">{{ $t('Producer') }}:</b> {{ getLabelFor('brand') }}
                </p>
                <p v-if="prod.sex && getLabelFor('gender')" class="description-text">
                  <b class="fs16">{{ $t('Gender') }}:</b> {{ getLabelFor('gender') }}
                </p>
              </div>
            </div>
          </div>
          <div v-if="selectedTab === 3 && index == selectedTab - 1" class="serif opis-container p10">
            <div v-show="!showOnlyPrenumerataDelivery" v-for="(shipping, indexThird) in thirdTab" :key="indexThird" class="flex mb10 middle-xs">
              <div class="col-xs-3"><img :src="shipping.img" alt="" height="100%" width="100%"></div>
              <div class="col-xs-9 h5 pr0">
                <span class="flex w-100">{{ shipping.html }}</span>
                <span v-show="indexThird !== 1" class="weight-900 h5">Płatność: {{ shipping.prepaid }}</span>
                <div v-show="indexThird === 1">
                  <span class="weight-900 h5">Paczka o wadze do 350 g - 7,00 zł</span><br>
                  <span class="weight-900 h5">Paczka o wadze 350-500 g - 9,00 zł</span><br>
                  <span class="weight-900 h5">Paczka o wadze 500-1000 g - 11,50 zł</span>
                </div>
              </div>
            </div>
            <div v-show="showOnlyPrenumerataDelivery && prod.type_id !== 'single_number'" class="flex col-xs-12 p0 mb10 weight-900">Dostawa prenumerat</div>
            <div v-show="showOnlyPrenumerataDelivery && prod.type_id !== 'single_number'" class="flex col-xs-12 p0 h5 mb10">
              W przypadku zamówienia prenumeraty koszt dostawy wliczony jest w cenę prenumeraty. Prenumerata realizowana jest wyłącznie na terenie Polski. Dostawa realizowana jest przez Pocztę Polską {{ showOnlyPrenumerataDelivery ? '(przesyłka rejestrowana)' : '(przesyłka nierejestrowana)' }}.
            </div>
            <div v-show="showOnlyPrenumerataDelivery && prod.type_id === 'single_number'" class="flex mb10 middle-xs">
              <div class="col-xs-3"><img src="/assets/InPost.svg" alt="Przesyłka kurierska InPost" height="100%" width="100%"></div>
              <div class="col-xs-9 h5 pr0">
                <span class="flex w-100">Przesyłka kurierska InPost</span>
                <span class="weight-900 h5 flex mt5">Płatność: 14,94 zł</span>
              </div>
            </div>
            <div v-show="!showOnlyPrenumerataDelivery" class="flex col-xs-12 p0 mb10 weight-900">Dostawa elektroniczna</div>
            <div v-show="!showOnlyPrenumerataDelivery" class="flex col-xs-12 p0 h5 mb10">
              Pliki cyfrowe jak e-wykroje i e-booki dostarczamy za darmo w formie linku do pobrania.
            </div>
            <div class="flex col-xs-12 p0 h5 mb10 weight-900">
              W przypadku zamówienia prenumeraty koszt dostawy wynosi 0 zł. Dostawa realizowana jest przez Pocztę Polską (przesyłka nierejestrowana).
            </div>
          </div>
          <div v-if="selectedTab == 4 && index == selectedTab - 1" class="opis-container">
            <div class="cl-black serif">
              <div class="row m0">
                <div class="col-xs-12 tab__container bg-cl-white h5 py30 px15 returns-tab">
                  <p class="m0">
                    Chcesz <b>zrezygnować</b> z zakupionego produktu? Nic prostszego. Możesz bezpiecznie zwrócić swój zakup <b>bez podawania przyczyny</b>, w terminie <b>14 dni</b> od odebrania paczki.
                    Odeślij nam produkt, a my <b>zwrócimy Ci środki</b> w ciągu 14 dni. Więcej informacji znajdziesz w zakładce
                    <router-link class="cl-main underline" :to="localizedRoute('/i/zwroty')">
                      <span class="weight-700">Zwroty</span>
                    </router-link>
                  </p>
                  <p>Jeśli nadajesz paczkę kurierem jako adres mailowy odbiorcy wpisz <b>bok@kultowy.pl</b></p>
                  <p v-show="isOmnipack">
                    Ten produkt należy zwrócić do magazyn Omnipack, sprawdź adres w zakładce
                    <router-link class="cl-main underline" :to="localizedRoute('/i/zwroty')">
                      <span class="weight-700">Zwroty</span>
                    </router-link>
                  </p>
                  <div class="flex flex-col middle-xs">
                    <img class="icons" src="/assets/fonts/return.svg" alt="return icon">
                    <p class="align-center mb0">
                      Przygotuj produkt do zwrotu i zanotuj numer zamówienia
                    </p>
                  </div>
                  <div class="flex flex-col middle-xs">
                    <img class="icons" src="/assets/fonts/local_store.svg" alt="local_store icon">
                    <p class="align-center mb0">
                      Wyślij paczkę do naszego magazynu <router-link class="cl-main underline" :to="localizedRoute('/i/zwroty')">
                      <span class="weight-700">Sprawdź adres</span>
                    </router-link>
                    </p>
                  </div>
                  <div class="flex flex-col middle-xs">
                    <img class="icons" src="/assets/fonts/revenue.svg" alt="revenue icon">
                    <p class="align-center mb0">
                      Zwrot środków wyślemy na rachunek użyty w zamówieniu
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="selectedTab == 5 && index == selectedTab - 1">
            <lazy-hydrate when-idle>
              <reviews
                :product-name="getOriginalProduct.name"
                :product-id="getOriginalProduct.id"
                :review-id="'mobile'"
                v-show="isOnline"
              />
            </lazy-hydrate>
          </div>
        </label>
      </ul>
    </section>
    <!-- Mobile version END -->
  </div>
</template>

<script>
import Reviews from 'theme/components/core/blocks/Reviews/Reviews.vue'
import { mapGetters } from 'vuex'
import LazyHydrate from 'vue-lazy-hydration'
import { ReviewModule } from '@vue-storefront/core/modules/review'
import { registerModule, isModuleRegistered } from '@vue-storefront/core/lib/modules'
import { onlineHelper, isServer } from '@vue-storefront/core/helpers'

export default {
  props: {
    prod: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      tabs: [
        { id: 1, title: 'Description', content: '' },
        { id: 2, title: 'Details', content: '' },
        { id: 3, title: 'Delivery', content: '' },
        { id: 4, title: 'Returns', content: '' },
        { id: 5, title: 'Reviews', content: '' }
      ],
      thirdTab: [
        {
          img: '/assets/pocztexlogo.png',
          html: 'Pocztex',
          prepaid: '13,50zł (waga do 20kg)'
        },
        {
          img: '/assets/Poczta_Polska.svg',
          html: 'Przesyłka Polecona Poczta Polska',
          prepaid: 'Paczka o wadze do 350 g - 7,00 zł, Paczka o wadze 350-500 g - 9,0 zł, '
        },
        {
          img: '/assets/InPost.svg',
          html: 'Paczkomaty 24/7 InPost',
          prepaid: '14,50 (waga do 25kg)'
        },
        {
          img: '/assets/dhl_logo.png',
          html: 'Kurier DHL',
          prepaid: '17,50 zł'
        }
      ],
      selectedTab: 1,
      detailsOpen: false,
      showDescription: false,
      showRewiew: false
    }
  },
  beforeCreate () {
    registerModule(ReviewModule)
  },
  components: {
    Reviews,
    LazyHydrate
  },
  methods: {
    getLabelFor (type) {
      if (this.listByCode && this.listByCode[type]) {
        let items = this.listByCode[type].options.filter(option => option.value === String(this.prod[type]))
        return (items && items.length) && items[0].label
      }
    },
    showDetails (event) {
      this.detailsOpen = true
      event.target.classList.add('hidden')
    },
    updateSelected (tab) {
      this.selectedTab = tab
    },
    updateSelectedMobile (tab) {
      this.selectedTab === tab || !this.selectedTab === ''
        ? this.selectedTab = ''
        : this.selectedTab = tab
    },
    toogleDescription () {
      this.showDescription = !this.showDescription
    },
    toogleRewiew () {
      this.showRewiew = !this.showRewiew
    }
  },
  computed: {
    premiereDate () {
      let temp = new Date(this.prod.book_premiere)
      let month = ('0' + (temp.getMonth() + 1)).slice(-2);
      let day = ('0' + temp.getDate()).slice(-2);
      let year = temp.getFullYear();
      return year + '/' + month + '/' + day;
    },
    ...mapGetters({
      getOriginalProduct: 'product/getOriginalProduct'
    }),
    showOnlyPrenumerataDelivery () {
      const ids = [140, 135, 117, 143, 150, 155, 156]
      return ids.includes(this.prod.magazine_id) && this.prod.type_id === 'subscription'
    },
    listByCode () {
      return this.$store.state.attribute.list_by_code
    },
    isOmnipack () {
      return this.prod && this.prod.stock && this.prod.stock.source_code && this.prod.stock.source_code.includes('omnipack')
    },
    isOnline (value) {
      return onlineHelper.isOnline
    }
  }
}

</script>

<style lang="scss" scoped>
.returns-tab {
  .icons {
    max-width: 90px;
    height: 100%;
  }
  .arrow-return {
    border: solid #1396EC;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 5px;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }
  .right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
}
.underline {
  text-decoration: underline;
}
.dostawa-container {
  height: 85px;
  max-height: 85px;
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    height: 85px;
    max-height: 85px;
  }
}
.dostawa-img {
  height: 25px;
  width: auto;
}
.opis-container {
  background: white;
  margin: 0 -15px;
}
.arrow {
  border: solid #010101;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 5px;
}
.top {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}
.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.description-text {
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}
.p30 {
  padding: 30px;
}
.shippingIcon {
  width: 50px;
  padding-right: 5px;
  height: 50px;
  object-fit: contain;
}
.label-tabs {
  padding: 10px 15px;
  @media (max-width: 767px) {
    padding: 0 15px;
  }
}
.tabs {
  .tablist {
    cursor: pointer;
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      display: inline-block;
      color: #191919;
      padding: 5px 0px;
      margin-right: 50px;
      margin-left: 30px;
      font-size: 18px;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      &.current {
        font-weight: 700;
        color: #1396EC;
      }
    }
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
      justify-content: center;
      li {
        font-size: 16px;
        margin-left: 10px;
      }
    }
  }
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .tab__container {
      flex-basis: 100%;
      max-width: 100%;
    }
  }
}
@media (max-width: 1200px) {
}

@media (max-width: 767px) {
  .mobile-datails {
    background-color: white;
    margin-top: 0;
    padding: 0;
  }
  .accord-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 10px 10px;
    border-top: 1px solid #e0e0e0;
  }
  .accord-button1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 10px 10px;
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
  }
  label {
    border-bottom: 1px solid #cfcfcf;
  }
  .tab-label.active {
    color: #1396EC !important;
    position: relative;
    transition: 0.1s;
    &:before {
      content: '';
      bottom: calc(50% - 5px);
      right: 5px;
      position: absolute;
      display: inline-block;
      -moz-border-radius: 5px;
      -webkit-border-radius: 5px;
      border-radius: 5px;
      background-color: #1396EC;
      transition: 0.1s;
      opacity: 0;
    }
    &:before {
      width: 10px;
      height: 10px;
      right: -10px;
      transition: 0.1s;
      opacity: 1;
    }
  }
}
</style>
